import { ReactNode } from "react";

import { motion, AnimatePresence, MotionProps } from "framer-motion";

interface FadeInAnimationType {
  InitialView: ReactNode;
  LoadedView: ReactNode;
  dataLoaded: boolean;
  style?: MotionProps["style"];
}

function FadeInAnimation({ dataLoaded, InitialView, LoadedView, style }: FadeInAnimationType) {
  const fadeProps = { animate: { opacity: 1 }, exit: { opacity: 0 }, initial: { opacity: 0 } };

  return (
    <AnimatePresence exitBeforeEnter>
      {!dataLoaded ? (
        <motion.div key="initial-view" {...fadeProps} style={style}>
          {InitialView}
        </motion.div>
      ) : (
        <motion.div {...fadeProps} style={style}>
          {LoadedView}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default FadeInAnimation;
