import { ReactElement } from "react";

import { gql } from "@apollo/client";
import axios from "axios";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import Head from "next/head";

import Login from "@/components/Login";
import {
  IdentityProvider,
  LoginPage__CurrentUserDocument,
  LoginPage__UserFragment,
  Product,
  Workspace,
} from "@/generated/graphql";
import useToggleColorModeKeyboard from "@/hooks/useToggleColorModeKeyboard";
import LoginTwoPanelLayout from "@/layouts/LoginTwoPanelLayout";
import { camelize } from "@/utils/caseHelpers";
import { craFrontendPath } from "@/utils/pathGenerators";
import { getServerSideQuery } from "@/utils/ssrQueryHelpers";

function LoginPage({ identityProviders }: InferGetServerSidePropsType<typeof getServerSideProps>) {
  useToggleColorModeKeyboard();

  return (
    <>
      <Head>
        <title>Release</title>
        <meta content="Environments as a Service" name="description" />
        <link href="/favicon.ico" rel="icon" />
      </Head>
      <Login identityProviders={identityProviders} />
    </>
  );
}

export default LoginPage;

LoginPage.getLayout = (page: ReactElement) => {
  return <LoginTwoPanelLayout>{page}</LoginTwoPanelLayout>;
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  let currentUser, identityProviders;
  try {
    const [identityProvidersResponse, { queryData }] = await Promise.all([
      axios.get(`${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/identity_providers`),
      getServerSideQuery({ context, query: LoginPage__CurrentUserDocument }),
    ]);

    identityProviders = identityProvidersResponse;
    currentUser = queryData.data.currentUser as LoginPage__UserFragment;

    if (currentUser?.id) {
      // TODO: Fix this... why do we need this in the first place?
      const hasVersionControl = currentUser.identities.nodes.some(
        identity => identity.identityProvider.versionControl
      );
      const products = queryData.data.workspaces.nodes
        .map((workspace: Workspace) => workspace.products)
        .flat();
      const uniqueProducts = Array.from(new Set(products));
      const standaloneDatasets =
        uniqueProducts.length === 1 && uniqueProducts[0] === Product.StandaloneDatasets;

      if (standaloneDatasets || hasVersionControl) {
        return {
          redirect: { permanent: false, destination: craFrontendPath("/admin/welcome") },
        };
      } else {
        return { redirect: { permanent: false, destination: "/register/connect" } };
      }
    }
  } catch (e) {
    console.log(e);
  }
  return {
    props: {
      identityProviders: camelize(identityProviders?.data ?? []) as unknown as IdentityProvider[],
    },
  };
}

gql`
  query LoginPage__CurrentUser {
    currentUser {
      ...LoginPage__User
    }
    workspaces {
      nodes {
        id
        products
      }
    }
  }

  fragment LoginPage__User on User {
    id
    identities {
      nodes {
        id
        identityProvider {
          id
          versionControl
        }
      }
    }
  }
`;
