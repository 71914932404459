import React, { useCallback, useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useForm, SubmitHandler } from "react-hook-form";
import * as yup from "yup";

import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  chakra,
  VStack,
  FormHelperText,
  Link,
} from "@chakra-ui/react";

import FadeInAnimation from "@/components/FadeInAnimation";
import SsoButtons from "@/components/SsoButtons";
import { IdentityProvider } from "@/generated/graphql";
import useLocalStorage from "@/hooks/useLocalStorage";
import useToast from "@/hooks/useToast";
import axiosClient from "@/utils/axiosClient";
const Form = chakra("form");

const loginSchema = yup.object({}).shape({
  email: yup
    .string()
    .email("Email must be a valid email address")
    .default(null)
    .nullable()
    .required("Valid email address required"),

  password: yup.string().default(null),
});

const emailSchema = yup.object().shape({
  email: yup.string().email("Email must be a valid email address").required("Valid email address required"),
});

/** Schemas */
type LoginValues = yup.InferType<typeof loginSchema>;

interface ApiError {
  error: string;
}

interface Props {
  identityProviders: IdentityProvider[];
}

function Login({ identityProviders }: Props) {
  const [lastUsedEmail, setLastUsedEmail] = useLocalStorage("lastUsedEmail", "");
  const router = useRouter();
  const toast = useToast({
    status: "error",
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm<LoginValues>({ resolver: yupResolver(loginSchema) });

  const [emailChecked, setEmailChecked] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<LoginValues> = useCallback(
    async formData => {
      const { email, password } = formData;
      try {
        setIsLoading(true);
        const response = await axiosClient.post("/users/sign_in", {
          user: {
            email,
            password,
          },
        });
        if (response.status === 200) {
          void router.push(response.data.redirect_url);
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 400) {
          setError("email", { type: "custom", message: "Either your username or password is incorrect" });
          toast({
            duration: 9000,
            status: "error",
            title: "Oops! An error has occurred.",
            description: "Either your username or password is incorrect",
            isClosable: true,
          });
        }

        if (err.response?.status === 401) {
          const error_message = (err.response.data as ApiError).error;
          setError("email", { type: "custom", message: error_message });
          toast({
            duration: 9000,
            status: "error",
            title: "Oops! An error has occurred.",
            description: error_message,
            isClosable: true,
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [router, setError, toast]
  );

  const isEnterpriseSso = async () => {
    const email = getValues("email");

    if (!email) {
      setError("email", { type: "custom", message: "Missing email address" });
      return;
    }

    if (emailSchema.isValidSync({ email })) {
      try {
        setIsLoading(true);
        setLastUsedEmail(email);
        /**
         * returns status 200 if this user is part of an enteripse SSO enabled account
         * returns status 422 if this user is not a part of an enterprise SSO enabled account
         * returns other statuses if something unexpectedly went wrong
         */
        const response = await axiosClient.post("/auth/validate_sso_domain", { email_address: email });

        if (response.status === 200) {
          void router.push("/login/enterprise");
        }
      } catch (error) {
        const err = error as AxiosError;
        if (err.response?.status === 422) {
          setEmailChecked(true);
        } else {
          toast({
            duration: 9000,
            status: "error",
            title: "Oops! An error has occurred.",
            description: err.message,
          });
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setError("email", { type: "custom", message: "Invalid email address" });
    }
  };

  useEffect(() => {
    if (!getValues("email") && lastUsedEmail) {
      setValue("email", lastUsedEmail);
    }
  }, [getValues, lastUsedEmail, setValue]);

  return (
    <Form maxW="md" w="full" onSubmit={handleSubmit(onSubmit)}>
      <Container mb={2} ml={0} p={0}>
        {/* Email address */}
        <VStack mb={2} spacing={2} w="full">
          <FormControl isInvalid={!!errors.email}>
            <FormLabel htmlFor="email">Email address</FormLabel>
            {!emailChecked && <FormErrorMessage>{errors.email?.message}</FormErrorMessage>}
            <InputGroup size="md">
              <Input
                disabled={isLoading}
                id="email"
                placeholder="registered.email@address.com"
                type="email"
                onKeyDown={event => {
                  clearErrors("email");
                  if (event.key === "Enter") {
                    event.preventDefault();
                    void isEnterpriseSso();
                  }
                }}
                {...register("email")}
              />
              {!emailChecked && (
                <InputRightElement width="4.5rem">
                  <Button
                    aria-label="Next"
                    h="1.75rem"
                    isDisabled={!watch("email")}
                    isLoading={isLoading}
                    size="sm"
                    variant="outline"
                    onClick={isEnterpriseSso}
                  >
                    <ArrowForwardIcon />
                  </Button>
                </InputRightElement>
              )}
            </InputGroup>
          </FormControl>
          <FadeInAnimation
            dataLoaded={emailChecked}
            InitialView={null}
            LoadedView={
              <FormControl isInvalid={!!errors.password} mb={2} w="full">
                <FormLabel htmlFor="password">Password</FormLabel>
                <InputGroup size="md">
                  <Input
                    disabled={isLoading}
                    id="password"
                    placeholder="Registered password"
                    type="password"
                    {...register("password")}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      aria-label="Next"
                      h="1.75rem"
                      isDisabled={!watch("password")}
                      isLoading={isLoading}
                      size="sm"
                      type="submit"
                      variant="outline"
                    >
                      <ArrowForwardIcon />
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormHelperText textAlign="right">
                  <NextLink passHref href="/reset-password">
                    <Link colorScheme="brand">Forgot password?</Link>
                  </NextLink>
                </FormHelperText>
              </FormControl>
            }
            style={{ width: "100%" }}
          />
        </VStack>
        <SsoButtons identityProviders={identityProviders} />
      </Container>
    </Form>
  );
}

export default Login;
