import React from "react";

import NextLink from "next/link";

import { Button, SimpleGrid } from "@chakra-ui/react";

import { BrandedIcon } from "@/components/shared/BrandedIcons/Brands";
import { IdentityProvider } from "@/generated/graphql";

interface Props {
  identityProviders: IdentityProvider[];
}

export default function SsoButtons({ identityProviders }: Props) {
  return (
    <SimpleGrid columns={1} spacing={2}>
      <SimpleGrid columns={2} spacing={2} w="full">
        {identityProviders.map(({ authenticationUrl, name }) => {
          return (
            <NextLink key={`authClient-${name}`} passHref href={authenticationUrl}>
              <Button
                aria-label={`${name}-button`}
                leftIcon={<BrandedIcon brand={name} />}
                variant="outline-shadow"
                width="100%"
              >
                {name}
              </Button>
            </NextLink>
          );
        })}
      </SimpleGrid>
      <NextLink passHref href="/login/enterprise">
        <Button variant="outline-shadow" w="full">
          Enterprise SSO
        </Button>
      </NextLink>
    </SimpleGrid>
  );
}
