import { useCallback, useMemo } from "react";

import Image from "next/legacy/image";

import {
  AlertStatus,
  useToast as useChakraToast,
  UseToastOptions as ChakraUseToastOptions,
  Stack,
  Text,
} from "@chakra-ui/react";

import ErrorSvg from "@/assets/images/statuses/error.svg";
import SuccessSvg from "@/assets/images/statuses/success.svg";

const icon = (status?: AlertStatus) => {
  switch (status) {
    case "error":
      return <Image alt="error icon" height={24} layout="fixed" src={ErrorSvg} width={24} />;
    case "success":
      return <Image alt="success icon" height={24} layout="fixed" src={SuccessSvg} width={24} />;
    default:
      return undefined;
  }
};

interface UseToastOptions extends ChakraUseToastOptions {
  action?: JSX.Element;
}

export default function useToast(props?: UseToastOptions) {
  const toastOptions = useMemo(() => {
    return {
      position: "top-right",
      variant: "toast",
      icon: icon(props?.status),
      ...props,
    };
  }, [props]);
  const chakraToast = useChakraToast(toastOptions as UseToastOptions);

  const toast = useCallback(
    (props?: UseToastOptions) => {
      if (props) {
        chakraToast({
          icon: icon(props?.status),
          ...props,
          description: (
            <Stack spacing={1}>
              {props?.description && <Text>{props?.description}</Text>}
              {props?.action && <Text>{props?.action}</Text>}
            </Stack>
          ),
        });
      }
      return chakraToast;
    },
    [chakraToast]
  );

  return toast;
}
