/* eslint-disable react/jsx-filename-extension */
import { useCallback, useEffect, useState } from "react";

import { getCookie, setCookie } from "cookies-next";

import { addDays } from "@/utils/date";

function useLocalStorage<T>(key: string, initialValue: T, expiresIn?: number) {
  const cookieKey = `release-localStorage-${key}`;
  const [storedValue, setStoredValue] = useState<T | undefined>();
  const cookie = getCookie(cookieKey);

  const removeValue = useCallback(() => {
    try {
      if (storedValue === undefined) throw new Error("storedValue is undefined");
      if (typeof window !== "undefined") {
        window.localStorage.removeItem(key);
        setStoredValue(undefined);
      }
    } catch (error) {
      console.log(error);
    }
  }, [key, storedValue]);

  useEffect(() => {
    setStoredValue(() => {
      if (typeof window === "undefined") {
        return initialValue;
      }
      try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        // If error also return initialValue
        console.log(error);
        return initialValue;
      }
    });

    if (expiresIn) {
      const now = new Date();
      if (!cookie) {
        if (storedValue && storedValue !== initialValue) {
          removeValue();
        }
        setCookie(cookieKey, true, { expires: addDays(now, expiresIn) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        if (storedValue === undefined) throw new Error("storedValue is undefined");
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [key, storedValue]
  );

  return [storedValue, setValue] as const;
}

export default useLocalStorage;
